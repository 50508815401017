import { FetchNotificationsResponse, PaginationProps } from '../types'
import initSdk from '../utils/initSdk'

export const fetchNotifications = async (pagination?: PaginationProps) => {
  await initSdk()

  const sessionSignature = localStorage.getItem('sessionSignature')

  if (!sessionSignature) {
    throw new Error('Session signature not found')
  }

  const { message, signature, publicKey } = JSON.parse(sessionSignature)

  const notifications = await fetch(
    `${
      process.env.NINA_ID_ENDPOINT
    }/notifications/${publicKey}?message=${message}&signature=${signature}&publicKey=${publicKey}&limit=${
      pagination?.limit || 25
    }&offset=${pagination?.offset || 0}`,
  )

  return (await notifications.json()) as FetchNotificationsResponse
}
