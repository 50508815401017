export const getTimeSince = (date: string) => {
  const currentDate = +new Date()
  const startDate = +new Date(date)
  const seconds = Math.floor((currentDate - startDate) / 1000)
  let interval = seconds / 31536000

  if (interval > 1) {
    const roundedInterval = Math.floor(interval)

    return roundedInterval + (roundedInterval === 1 ? ' year' : ' years')
  }

  interval = seconds / 2592000

  if (interval > 1) {
    const roundedInterval = Math.floor(interval)

    return roundedInterval + (roundedInterval === 1 ? ' month' : ' months')
  }

  interval = seconds / 86400

  if (interval > 1) {
    const roundedInterval = Math.floor(interval)

    return roundedInterval + (roundedInterval === 1 ? ' day' : ' days')
  }

  interval = seconds / 3600

  if (interval > 1) {
    const roundedInterval = Math.floor(interval)

    return roundedInterval + (roundedInterval === 1 ? ' hour' : ' hours')
  }

  interval = seconds / 60

  if (interval > 1) {
    const roundedInterval = Math.floor(interval)

    return roundedInterval + (roundedInterval === 1 ? ' minute' : ' minutes')
  }

  return Math.floor(seconds) + ' seconds'
}
