import { truncatePublicKey } from '@/lib/utils/truncatePublickey'
import { Account, ProfileAuthorized } from '../types'
import { getImage } from './imgix'

export enum AccountDisplayType {
  Name,
  Image,
  Description,
}

export const displayForAccount = (
  account: Account | ProfileAuthorized,
  displayType: AccountDisplayType,
  width = 200,
) => {
  switch (displayType) {
    case AccountDisplayType.Name:
      return account?.displayName || truncatePublicKey(account?.publicKey)

    case AccountDisplayType.Image:
      let imageUrl = account?.image

      if (imageUrl) {
        if (imageUrl.includes('arweave')) {
          imageUrl = getImage(imageUrl, width)
        }
      }

      return imageUrl || '/assets/empty-user.png'
    case AccountDisplayType.Description:
      return account?.description || ''
  }
}
