// FOR DEVELOPMENT: MOVE THIS FUNCTION TO THE SDK
import { PublicKeyString } from '../types'

export const truncatePublicKey = (publicKey: PublicKeyString) => {
  if (!publicKey) return ''

  return `${publicKey.slice(0, 3)}...${publicKey.slice(
    publicKey.length - 3,
    publicKey.length,
  )}`
}
