import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { Account, NotificationItem, NotificationType } from '@/lib/types'
import {
  AccountDisplayType,
  displayForAccount,
} from '@/lib/utils/displayForAccount'
import { getTimeSince } from '@/lib/utils/getTimeSince'
import { truncatePublicKey } from '@/lib/utils/truncatePublickey'
import ImageWithArweaveFallbackClient from './ImageWithArweaveFallbackClient'
import Icon from './tokens/Icon'

type NotificationListItemProps = {
  notification: NotificationItem
  onClick?: () => void
  isNotificationPage?: boolean
}

export default function NotificationListItem({
  notification,
  onClick,
  isNotificationPage,
}: NotificationListItemProps) {
  const renderNotificationListItem = () => {
    const { authority } = notification

    const handleNotificationClick = () => {
      if (onClick) {
        onClick()
      }
    }

    const fontType = isNotificationPage ? 'body-1' : 'caption-1'

    const NotificationListItemMessage = ({
      authority,
      recipient,
      action,
    }: {
      authority: Account
      recipient?: NotificationItem
      action: string
    }) => {
      return (
        <div className="flex flex-row pb-12 !leading-none">
          <p>
            <Link
              href={`/profiles/${authority.handle || authority.publicKey}`}
              onClick={() => handleNotificationClick()}
              className={clsx('text-black  md:hover:opacity-70', fontType)}
            >
              {displayForAccount(authority, AccountDisplayType.Name) ||
                truncatePublicKey(authority.publicKey)}
            </Link>{' '}
            <span className={clsx('text-grey-45', fontType)}>{action}</span>
            {recipient?.release &&
              recipient?.type !== NotificationType.HubAddRelease && (
                <Link
                  href={`/releases/${
                    recipient.release?.slug || recipient.release?.publicKey
                  }`}
                  className={clsx(
                    '!leading-none text-grey-45 hover:opacity-80',
                    fontType,
                  )}
                >
                  {' '}
                  {recipient?.release?.metadata.properties.title}
                </Link>
              )}
            {recipient?.hub &&
              recipient.type !== NotificationType.ReleasePurchaseViaHub && (
                <span
                  className={clsx(
                    'text-blue-82  hover:text-opacity-80',
                    fontType,
                  )}
                >
                  <Link
                    href={`/hubs/${recipient.hub?.handle}`}
                    onClick={() => handleNotificationClick()}
                  >
                    {' '}
                    {recipient.hub?.data.displayName}
                  </Link>
                </span>
              )}
            {recipient?.toHub && (
              <span
                className={clsx('text-blue-82 hover:text-opacity-80', fontType)}
              >
                <Link
                  href={`/hubs/${recipient.toHub?.handle}`}
                  onClick={() => handleNotificationClick()}
                >
                  {' '}
                  {recipient.toHub?.data.displayName}
                </Link>
              </span>
            )}
          </p>
        </div>
      )
    }

    const NotificationAuthorityImage = ({
      authority,
    }: {
      authority: Account
    }) => {
      return (
        <div>
          <Link
            href={`/profiles/${authority.handle || authority.publicKey}`}
            onClick={() => handleNotificationClick()}
          >
            <Image
              src={displayForAccount(authority, AccountDisplayType.Image, 100)}
              alt={authority?.publicKey}
              height={100}
              width={100}
              className="imageBorderRound h-40 w-40 rounded-full md:hover:opacity-80"
            />
          </Link>
        </div>
      )
    }

    const NotificationRecipientImage = ({
      link,
      image,
      alt,
      type,
    }: {
      link: string
      image: string
      alt: string
      type: 'release' | 'hub' | 'profile'
    }) => {
      return (
        <>
          <Link href={link} onClick={() => handleNotificationClick()}>
            {type === 'hub' && (
              <ImageWithArweaveFallbackClient
                src={image}
                alt={alt || ''}
                height={100}
                width={100}
                className="imageBorder h-40 w-40 md:hover:opacity-80"
              />
            )}
            {type === 'profile' && (
              <Image
                src={image}
                alt={alt}
                height={100}
                width={100}
                className="imageBorderRound h-40 w-40 rounded-full md:hover:opacity-80"
              />
            )}
            {type === 'release' && (
              <ImageWithArweaveFallbackClient
                src={image}
                alt={alt || ''}
                height={100}
                width={100}
                className="imageBorder h-40 w-40 md:hover:opacity-80"
              />
            )}
          </Link>
        </>
      )
    }

    switch (notification.type) {
      case NotificationType.SubscriptionSubscribeAccount:
        return (
          <div>
            <NotificationListItemMessage
              action="followed you"
              authority={authority}
            />

            <div className="flex flex-row justify-between">
              <NotificationAuthorityImage authority={authority} />
              <p
                className={clsx('self-end align-bottom text-grey-45', fontType)}
              >
                {getTimeSince(
                  new Date(notification.blocktime * 1000).toLocaleString(),
                )}
              </p>
            </div>
          </div>
        )
      case NotificationType.SubscriptionSubscribeHub: {
        let hubImage

        if (
          notification.toHub?.data.image &&
          notification.toHub?.data.image !== ''
        ) {
          hubImage = notification.toHub?.data.image
        }

        return (
          <>
            <NotificationListItemMessage
              action="followed"
              authority={authority}
              recipient={notification}
            />
            <div className="flex justify-between">
              <div className="flex gap-x-12">
                <NotificationAuthorityImage authority={authority} />
                <NotificationRecipientImage
                  link={`/hubs/${notification?.toHub?.handle}`}
                  image={hubImage || '/assets/empty-user.png'}
                  alt={notification?.toHub?.data.displayName ?? ''}
                  type="hub"
                />
              </div>
              <p
                className={clsx('self-end align-bottom text-grey-45', fontType)}
              >
                {getTimeSince(
                  new Date(notification.blocktime * 1000).toLocaleString(),
                )}
              </p>
            </div>
          </>
        )
      }
      case NotificationType.ReleaseClaim:
      case NotificationType.ReleasePurchase:
      case NotificationType.ReleasePurchaseViaHub: {
        return (
          <>
            <NotificationListItemMessage
              action="collected"
              authority={authority}
              recipient={notification}
            />

            <div className="flex justify-between">
              <div className="flex gap-x-12">
                <NotificationAuthorityImage authority={authority} />
                <NotificationRecipientImage
                  link={`/releases/${
                    notification.release?.slug ||
                    notification.release?.publicKey
                  }`}
                  type="release"
                  image={notification.release?.metadata.image || ''}
                  alt={notification.release?.metadata.properties.title || ''}
                />

                {/* FOR DEVELOPMENT: handles multi-track purchases, waiting on update in backend */}

                {/* {notification.release.map(
                  async (releaseItem: any, index: number) => {
                    const { release } = await fetchRelease(
                      releaseItem.publicKey,
                    )

                    return (
                      <>
                        {index < 4 && (
                          <Link href={`/releases/${release.publicKey}`}>
                            <Image
                              src={release.metadata.image}
                              alt={release.metadata.properties.title}
                              height={100}
                              width={100}
                              className="w-40 h-40"
                            />
                          </Link>
                        )}
                      </>
                    )
                  },
                )}
                 {notification.release.length > 4 && (
                  <div className="flex items-center justify-center w-40 h-40 border cursor-default">
                    <p className={clsx("text-center text-grey-45", fontType)}>
                      +{notification.release.length}
                    </p>
                  </div>
                )} */}
              </div>
              <p
                className={clsx('self-end align-bottom text-grey-45', fontType)}
              >
                {getTimeSince(
                  new Date(notification.blocktime * 1000).toLocaleString(),
                )}
              </p>
            </div>
          </>
        )
      }

      case NotificationType.HubAddCollaborator: {
        return (
          <>
            <NotificationListItemMessage
              action="added you as a collaborator to"
              authority={authority}
              recipient={notification}
            />
            <div className="flex justify-between">
              <div className="flex gap-x-12">
                <NotificationAuthorityImage authority={authority} />
                <NotificationRecipientImage
                  link={`/hubs/${notification.hub?.handle}`}
                  image={notification.hub?.data.image || ''}
                  alt={notification.hub?.handle || ''}
                  type="hub"
                />
              </div>
              <p
                className={clsx('self-end align-bottom text-grey-45', fontType)}
              >
                {getTimeSince(
                  new Date(notification.blocktime * 1000).toLocaleString(),
                )}
              </p>
            </div>
          </>
        )
      }
      case NotificationType.HubAddRelease: {
        return (
          <>
            <NotificationListItemMessage
              action={'reposted your release to'}
              authority={authority}
              recipient={notification}
            />

            <div className="flex justify-between">
              <div className="flex gap-x-12">
                <NotificationAuthorityImage authority={authority} />

                <div className="flex items-center">
                  <Icon
                    name="repost"
                    className="grey-45"
                    width={20}
                    height={20}
                  />
                </div>

                <NotificationRecipientImage
                  link={`/releases/${
                    notification.release?.slug ||
                    notification.release?.publicKey
                  }`}
                  image={notification.release?.metadata.image || ''}
                  alt={notification.release?.metadata.name || ''}
                  type="release"
                />
              </div>
              <p
                className={clsx('self-end align-bottom text-grey-45', fontType)}
              >
                {getTimeSince(
                  new Date(notification.blocktime * 1000).toLocaleString(),
                )}
              </p>
            </div>
          </>
        )
      }
      default:
        //Note: default state currently renders empty LI - waiting for backend notifications update to handle appropriately
        return null
    }
  }

  return (
    <li className="flex flex-col border-b-1 border-grey-10 pb-20 pt-12 first:pt-0 last:mb-0 last:border-none">
      <div className="flex flex-col">{renderNotificationListItem()}</div>
    </li>
  )
}
