'use client'

import { NotificationItem, NotificationType } from '@/lib/types'
import NotificationListItem from './NotificationListItem'

type NotificationsProps = {
  notifications: NotificationItem[]
  onNotificationClick?: () => void
  isNotificationPage?: boolean
}

export default function NotificationsList({
  notifications,
  onNotificationClick,
  isNotificationPage,
}: NotificationsProps) {
  const handleNotificationClick = () => {
    if (onNotificationClick) {
      onNotificationClick()
    }
  }

  if (notifications && isNotificationPage && notifications.length === 0) {
    return (
      <p className="body-1 text-grey-45">{`You don't have any notifications.`}</p>
    )
  }

  return (
    <ul className="w-full">
      {notifications &&
        notifications
          ?.filter(
            (notification) =>
              notification.type !==
              (NotificationType.ReleaseRevenueShareTransfer ||
                NotificationType.ReleaseRevenueShareCollect),
          )
          .map((notification: NotificationItem, index: number) => {
            return (
              <>
                <NotificationListItem
                  key={index}
                  notification={notification}
                  onClick={() => handleNotificationClick()}
                  isNotificationPage={isNotificationPage}
                />
              </>
            )
          })}
    </ul>
  )
}
